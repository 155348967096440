import { defineStore } from "pinia";

export const useEnrolledCourseStore = defineStore("enrolledCourseStore", {
  state: () => ({
    viewedEnrolledCourse: [],
    label: "All Courses",
    selectedFilter: null,
  }),
  actions: {
    setViewCourse(course) {
      if (
        this.viewedEnrolledCourse.map((course) => course.id).includes(course.id)
      ) {
        return;
      }

      this.viewedEnrolledCourse.push(course);
    },
    removeCourse(courseId) {
      this.viewedEnrolledCourse = this.viewedEnrolledCourse.filter(
        (course) => course.id != courseId
      );
    },
  },
});
