<template>
  <nav
    class="flex border-b border-gray-200 bg-white"
    aria-label="Breadcrumb"
    v-if="pages.length > 1"
  >
    <ol
      role="list"
      class="flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8"
    >
      <li class="flex">
        <div class="flex items-center">
          <router-link to="/" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li v-for="page in filteredPages" :key="page.name" class="flex">
        <div class="flex items-center">
          <svg
            class="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <router-link
            :to="page.route"
            class="ml-4 relative text-sm font-medium text-gray-500 hover:text-gray-700"
            @mouseleave="handleMouseLeave"
            @mouseover="handleMouseMove(page)"
            @click="handleClickRoute(page)"
            :aria-current="page.current ? 'page' : undefined"
          >
            {{ page.name }}
            <div
              class="absolute z-50 max-w-96 w-72 py-2 px-4 text-untitled-gray-500 bg-untitled-gray-100 shadow-md"
              v-if="page.description && pageTitle == page.name"
            >
              {{ page.description }}
            </div>
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { HomeIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";
import { watch, computed, ref, onMounted } from "vue";
import { useBreadcrumbsStore } from "@/composable/breadcrumbs";
import { useUnitStore } from "@/stores/unitPage.store";
import EventBus from "@/plugins/eventBus";

const route = useRoute();
const unitStore = useUnitStore();
const unit = computed(() => unitStore.unit);

const breadcrumbStore = useBreadcrumbsStore();
const showDescription = ref(false);
const pageTitle = ref(null);

const pages = computed(() => breadcrumbStore.breadcrumbs);

const filteredPages = computed(() =>
  pages.value.filter((page) => page.name !== "Home")
);

const setRouter = () => {
  breadcrumbStore.setBreadcrumbs(route);
};

const handleClickRoute = (routeData) => {
  breadcrumbStore.clickRoute(routeData);
};

const setBreadcrumbsDescription = (description) => {
  let title = route.meta.title;
  if (
    title &&
    (title.toLowerCase() == "unit" || title.toLowerCase() == "edit unit") &&
    description
  ) {
    breadcrumbStore.addDescription(route, description);
  }

  if (
    title &&
    (title.toLowerCase() == "unit" || title.toLowerCase() == "edit unit") &&
    !description
  ) {
    breadcrumbStore.addDescription(route, unit.value.unit.name);
  }

  if (title && title.toLowerCase() == "edit courses" && description) {
    breadcrumbStore.addDescription(route, description);
  }

  if (title && title.toLowerCase() == "manage courses" && description) {
    breadcrumbStore.addDescription(route, description);
  }
};

const handleMouseMove = (page) => {
  showDescription.value = true;
  pageTitle.value = page.name;
};

const handleMouseLeave = () => {
  showDescription.value = false;
  pageTitle.value = null;
};

onMounted(() => {
  EventBus.$on("COURSE_BREADCRUMBS", (title) => {
    setBreadcrumbsDescription(title);
  });

  EventBus.$on("MANAGE_COURSES", (title) => {
    setBreadcrumbsDescription(title);
  });

  EventBus.$on("UNIT_PAGE", (title) => {
    setBreadcrumbsDescription(title);
  });
});

watch(route, () => {
  setRouter();
  let routeData = {
    name: route.meta.title,
    route: {
      name: route.name,
      params: route.params,
    },
  };
  handleClickRoute(routeData);
  setBreadcrumbsDescription();
});
</script>
