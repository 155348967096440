import ReminderModel from "@/model/ReminderModel";
import dayjs from "dayjs";
import { useHandleErrorStatus } from "../useHandleErrorStatus";
import { ref } from "vue";
import { reminderEndpoints } from "@/endpoints/reminder";

const data = ref([]);
const loading = ref(true);
const lastPage = ref(1);

export const fetchReminders = () => {
  const load = async (params) => {
    loading.value = true;
    const {
      isError,
      error,
      data: reminders,
      loading: loadingReminder,
    } = await new ReminderModel(reminderEndpoints.resource).get(params);

    loading.value = loadingReminder;
    if (isError && error) {
      useHandleErrorStatus(error);
    }
    lastPage.value = reminders.goals.last_page;

    const remindersData = reminders.goals.data.map((goal) => {
      return formatReminderResponse(goal, reminders);
    });

    if (params.page == 1) {
      data.value = remindersData;
    } else {
      data.value.push(...remindersData);
    }

    data.value = data.value.filter((reminder) => reminder.id);
  };

  return {
    load,
    data,
    lastPage,
    loading,
  };
};

export const formatReminderResponse = (goal) => {
  const date = dayjs(goal.schedule_date).format("MM/DD/YYYY");
  const time = goal.schedule_time ? goal.schedule_time : "";
  const dateLong = `${date} ${time}`;

  goal.assignedDateTime = goal?.schedule_date
    ? dayjs(dateLong).format("MM/DD/YYYY hh:mm A")
    : "No date";
  goal.assignor = goal?.author?.name;
  goal.selectUsers = goal & goal.users ? goal.users : [];

  return goal;
};
