<template>
  <Popover class="relative">
    <PopoverButton
      class="relative bg-white p-1 rounded-full text-untitled-gray-400 hover:text-untitled-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
    >
      <BellIcon class="h-6 w-6" aria-hidden="true" />
      <div
        v-if="totalNotification > 0"
        class="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900"
      >
        {{ totalNotification }}
      </div>
    </PopoverButton>

    <PopoverPanel
      class="origin-top-right right-0 mt-2 absolute z-10 bg-white shadow-md h-[450px] overflow-y-auto overflow-x-hidden"
    >
      <aside class="xl:order-first xl:block xl:flex-shrink-0">
        <div
          class="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100"
        >
          <div class="flex-shrink-0">
            <div class="flex h-16 flex-col justify-center bg-white px-6">
              <div class="flex items-baseline space-x-3">
                <h2 class="text-lg font-medium text-gray-900">
                  New Notifications
                </h2>
                <p class="text-sm font-medium text-gray-500"></p>
              </div>
            </div>
            <div
              v-if="notifications.length > 0"
              class="cursor-pointer border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500"
              @click="markAllAsRead"
            >
              Mark all as read
            </div>
          </div>
          <nav aria-label="Message list" class="min-h-0 flex-1 overflow-y-auto">
            <ul
              v-if="notifications.length > 0"
              role="list"
              class="divide-y divide-gray-200 border-b border-gray-200"
            >
              <li
                v-for="notification in notifications"
                :key="notification"
                class="relative py-5 px-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50"
                :class="
                  notification.read_at ? 'bg-white ' : 'bg-untitled-gray-100'
                "
              >
                <div
                  class="mt-1 cursor-pointer"
                  @click="openPage(notification)"
                >
                  <p class="text-sm text-gray-600 line-clamp-2">
                    {{
                      notification.message
                        ? notification.message
                        : notification.data.message
                    }}
                  </p>
                </div>
              </li>
            </ul>
            <div v-else class="flex justify-center items-center h-full">
              <p class="text-gray-500">No notifications found.</p>
            </div>
          </nav>
        </div>
      </aside>
    </PopoverPanel>
  </Popover>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { BellIcon } from "@heroicons/vue/outline";
import { inject, ref, onMounted, computed } from "vue";
import Bugsnag from "@bugsnag/js";
import { useAuthStore } from "@/stores";
import EventBus from "@/plugins/eventBus";
import { useRouter } from "vue-router";

const axios = inject("axios");
const notifications = ref([]);
const notifCounter = ref(0);
const loading = ref(true);
const router = useRouter();

const totalNotification = computed(() => {
  return notifCounter.value;
});
const notifTotalItem = ref(0);

async function markAllAsRead() {
  await axios
    .post("/api/notifications/read-all")
    .then(() => {
      fetchAllNotifications();
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
}

function openPage(notification) {
  markAsRead(notification);
}

async function markAsRead(notification) {
  const { id } = notification;
  await axios
    .post("/api/notifications/read", { id })
    .then(() => {
      fetchAllNotifications();
      window.open(notification?.data?.link ?? notification.data.link, "_self");
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
}

async function fetchAllNotifications() {
  notifTotalItem.value += 10;
  loading.value = true;
  await axios
    .get("/api/notifications", {
      page: 1,
      totalPage: notifTotalItem.value,
    })
    .then((response) => {
      notifications.value = response.data.all.data;
      notifCounter.value = response.data.unread;
      loading.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    })
    .finally(() => {
      loading.value = false;
    });
}

const authUser = useAuthStore();

onMounted(() => {
  fetchAllNotifications();

  window.Echo.channel("notification-channel").listen(
    "Notification",
    (notification) => {
      if (notification.user.id === authUser.user.id) {
        notifications.value.unshift(notification);
        notifCounter.value += 1;
      }
    }
  );

  window.Echo.private("App.Models.User." + authUser.user.id).notification(
    (notification) => {
      notifications.value.unshift(notification);
      notifCounter.value += 1;

      const link = notification.type === "App\\Notifications\\ReminderNotification" ? notification.link : null

      EventBus.$emit("CREATE_NOTIFICATION", notification.message, 'info', 7000,link);

      if (
        notification.type ===
        "App\\Notifications\\ActiveRolePlayTranscodeComplete"
      ) {
        handleActiveRolePlayTranscodeCompleteNotification();
      }
    }
  );
});

const handleActiveRolePlayTranscodeCompleteNotification = () => {
  if (router.currentRoute.value.name === "units") {
    EventBus.$emit("ACTIVE_ROLE_PLAY_REFRESH");
  }
};
</script>
