<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <ul
        class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
        v-if="!isSingleUser"
      >
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-sm font-bold px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer w-full"
            v-on:click="toggleTabs(2)"
            v-bind:class="{
              'text-untitled-gray-600 bg-untitled-gray-200': openTab !== 2,
              'text-untitled-gray-600 bg-untitled-gray-200': openTab === 2,
            }"
          >
            <CalendarIcon
              class="h-5 w-5 text-untitled-gray-700 inline"
              aria-hidden="true"
            />
            Events
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-sm font-bold px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer w-full"
            v-on:click="toggleTabs(1)"
            v-bind:class="{
              'text-untitled-gray-600 bg-untitled-gray-200': openTab !== 1,
              'text-untitled-gray-600 bg-untitled-gray-200': openTab === 1,
            }"
          >
            <ClipboardListIcon
              class="h-5 w-5 text-untitled-gray-700 inline"
              aria-hidden="true"
            />
            Reminders
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      >
        <div :class="[!isSingleUser ?? 'px-4 py-5 flex-auto']">
          <div class="tab-content tab-space">
            <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }">
              <p>
                <reminders-list
                  :reminders="sortedReminders"
                  :users="users"
                  :loading="loading"
                ></reminders-list>
              </p>
            </div>
            <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
              <p>
                <upcoming-events></upcoming-events>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NotificationDialog
    v-if="showNotificationDialog"
    @close="showNotificationDialog = false"
  />
</template>
<script setup>
import RemindersList from "../events/RemindersList.vue";
import UpcomingEvents from "../events/UpcomingEvents.vue";
import { CalendarIcon, ClipboardListIcon } from "@heroicons/vue/outline";
import EventBus from "@/plugins/eventBus";
import Bugsnag from "@bugsnag/js";
import { inject, ref, computed, onMounted, provide, onUnmounted } from "vue";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import { fetchReminders } from "@/composable/reminder/get-reminders";
import { useAuthStore } from "@/stores";

const axios = inject("axios");
const { load: loadReminders, data: reminders, loading } = fetchReminders();

const openTab = ref(2);
const users = ref([]);
const showNotificationDialog = ref(false);
const params = ref({
  page: 1,
  limit: 5,
  fetchFromHome: true,
});

const authUser = useAuthStore();
const isSingleUser = computed(() => authUser.isSingleUser);

const selectedCompanyIds = ref([]);
const selectedDealerIds = ref([]);

const usersParams = computed(() => {
  return {
    dealer_ids: selectedCompanyIds.value,
    specific_dealer_ids: selectedDealerIds.value,
  };
});

const sortedReminders = computed(() => {
  let sortedReminder = reminders.value;

  if (sortedReminder.length == 0) {
    return [];
  }

  return sortedReminder.sort(
    (a, b) => new Date(a.assignedDateTime) - new Date(b.assignedDateTime)
  );
});

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

const getUsers = async () => {
  let params = usersParams.value;

  await axios
    .get("/api/client/lms/fetchUsersByDealer", { params })
    .then((res) => {
      users.value = res.data;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const handleSelectedCompanyDealer = (companyIds, dealerIds) => {
  selectedCompanyIds.value = companyIds;
  selectedDealerIds.value = dealerIds;
  getUsers();
};

onMounted(() => {
  getUsers();
  loadReminders(params.value);

  EventBus.$on("NEW_REMINDER_HOMEPAGE", () => {
    loadReminders(params.value);
    EventBus.$emit(
      "CREATE_NOTIFICATION",
      "Saved successfully",
      "success",
      6500
    );
  });

  if (isSingleUser.value) {
    openTab.value = 1;
  }
});

onUnmounted(() => {
  EventBus.$off("NEW_REMINDER_HOMEPAGE")
})

provide("homePageReminder", {
  handleSelectedCompanyDealer,
});
</script>
