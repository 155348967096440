export const getStatusColor = (status) =>  {
  if (status == "completed" || status == "Completed") {
    return "text-green-500";
  } else if (status == "overdue" || status == "Overdue") {
    return "text-red-500";
  } else if (status == "incomplete" || status == "In Progress") {
    return "text-yellow-500";
  } else {
    return "text-gray-500";
  }
}