<script setup>
import {
  defineProps,
  ref,
  onUnmounted,
  computed,
  inject,
  onMounted,
  defineEmits,
  watch,
} from "vue";

import ECombobox from "@/components/element-components/ECombobox";
import EventBus from "@/plugins/eventBus";
import { postReminderPayload } from "@/composable/reminder/reminder-payload";
import { postReminder } from "@/composable/reminder/post-reminder";
import TimezoneSelector from "@/components/utilities/TimezoneSelector.vue";
import EDatePicker from "@/components/element-components/EDatePicker.vue";
import { useAuthStore } from "@/stores";
import { validateHtmlScriptString } from "@/helpers/text_formatter";
import Bugsnag from "@bugsnag/js";
import GlobalDialog from "@/components/layout/GlobalDialog.vue";

const props = defineProps({
  isOpen: {
    default: false,
    type: Boolean,
  },
  handleCloseModal: {
    type: Function,
  },
  users: {
    default: [],
  },
  eventBusName: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["changeCompany", "changeDealer"]);

const axios = inject("axios");
const reminder = ref({
  name: "",
  description: "",
  date: null,
  time: null,
  message: "",
  timezone: null,
});

const authUser = useAuthStore();

const isOpen = computed({
  get() {
    return props.isOpen;
  },
  set(newValue) {
    newValue;
  },
});
const selectedUsers = ref([]);
const selectedCompanies = ref([]);
const companies = ref([]);
const selectedDealers = ref([]);
const dealers = ref([]);
const companyLoading = ref(false);
const errorData = ref(null);
const isLoading = ref(false);
const errors = ref({
  name: { fieldName: "name", error: null },
  description: { fieldName: "description", error: null },
  date: { fieldName: "date", error: null },
  time: { fieldName: "time", error: null },
  timezone: { fieldName: "timezone", error: null },
  assignUsers: { fieldName: "assignUsers", error: null },
});
const isSingleUser = computed(() => authUser.isSingleUser);

const isSuperAdmin = computed(() => {
  const user = authUser.user;

  if (!user) {
    return false;
  }

  return user.roles.some((role) => role.name === "super-administrator");
});

const isAccountManager = computed(() => {
  const user = authUser.user;
  if (!user) {
    return false;
  }

  return user.roles.some((role) => role.name === "account-manager");
});

const filteredDealers = computed(() => {
  if (selectedCompanies.value && selectedCompanies.value.length) {
    return dealers.value.filter((dealer) =>
      selectedCompanies.value.includes(dealer.dealer_id)
    );
  }

  return dealers.value;
});

const closeModal = () => {
  props.handleCloseModal();
};
const isValid = () => {
  let valid = true;

  errors.value = {
    name: { fieldName: "name", error: null },
    description: { fieldName: "description", error: null },
    message: { fieldName: "message", error: null },
    date: { fieldName: "date", error: null },
    time: { fieldName: "time", error: null },
    timezone: { fieldName: "timezone", error: null },
    assignUsers: { fieldName: "assignUsers", error: null },
  };

  if (!reminder.value.name.length) {
    valid = false;
    errors.value.name.error = "The name field is required";
  }

  console.log(reminder.value.timezone);
  if (reminder.value.timezone === null) {
    valid = false;
    errors.value.timezone.error = "The timezone field is required";
  }

  if (!reminder.value.description.length) {
    valid = false;
    errors.value.description.error = "The description field is required";
  }

  if (validateHtmlScriptString(reminder.value.description)) {
    valid = false;
    errors.value.description.error =
      "The string contains HTML tags or script. Please enter a valid string.";
  }

  if (!reminder.value.date) {
    valid = false;
    errors.value.date.error = "Schedule date is required.";
  }

  return valid;
};

const saveGoal = async () => {
  if (!isValid()) {
    return;
  }

  isLoading.value = true;
  reminder.value.selectedCompanies = selectedCompanies.value;
  reminder.value.selectedDealers = selectedDealers.value;
  const payload = postReminderPayload(reminder.value, selectedUsers.value);
  const { data, isError, errorValue } = await postReminder(payload);

  if (isError && errorValue.value) {
    errorData.value = errorValue.value;
    isLoading.value = false;
    return;
  }

  if (data && data.id) {
    EventBus.$emit(props.eventBusName, data);
  }

  closeModal();
  isLoading.value = false;
};

const getCompaniesAndDealers = async () => {
  companyLoading.value = true;

  await axios
    .get("/api/companies-and-dealers")
    .then((response) => {
      dealers.value = response.data.data.dealers;
      companies.value = response.data.data.companies;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    })
    .finally(() => {
      companyLoading.value = false;
    });
};

const handleChangeCompany = (company) => {
  emit("changeCompany", company);
};

const handleChangeDealer = (dealer) => {
  emit("changeDealer", dealer);
};

onMounted(() => {
  getCompaniesAndDealers();
});

onUnmounted(() => {
  reminder.value = {
    name: "",
    description: "",
    date: null,
    time: null,
    message: "",
    timezone: null,
  };
  selectedUsers.value = [];
});

watch(isOpen, (value) => {
  if (value) {
    getCompaniesAndDealers();
  }
});
</script>

<template>
  <GlobalDialog
    v-model="isOpen"
    dialog-default-size="40%"
    title="New Reminder"
    submit-label="Save"
    cancel-label="Cancel"
    :is-loading="isLoading"
    loading-label="Saving..."
    @onSubmit="saveGoal"
    @onCancel="closeModal"
  >
    <template #content>
      <div class="mt-5 flex flex-col px-4 gap-y-2 h-full">
        <div>
          <label class="mb-2 text-sm">Name</label>
          <input
            v-model="reminder.name"
            type="text"
            placeholder="Reminder Name"
            required
            class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm"
            :disabled="isLoading"
          />
          <p v-if="errors.name" class="mt-2 text-red-500 text-xs italic">
            {{ errors.name.error }}
          </p>
        </div>

        <div>
          <label class="mb-2 text-sm">Description</label>
          <textarea
            placeholder="Description"
            v-model="reminder.description"
            class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm h-[5rem] min-h-[5rem] max-h-[5rem]"
            :disabled="isLoading"
            maxlength="255"
          >
          </textarea>
          <p v-if="errors.description" class="mt-2 text-red-500 text-xs italic">
            {{ errors.description.error }}
          </p>
        </div>
        <div>
          <label class="mb-2 text-sm">Message</label>
          <textarea
            placeholder="Message"
            v-model="reminder.message"
            class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 sm:text-sm h-[5rem] min-h-[5rem] max-h-[5rem]"
            :disabled="isLoading"
            maxlength="255"
          >
          </textarea>
          <p v-if="errors.message" class="mt-2 text-red-500 text-xs italic">
            {{ errors.message.error }}
          </p>
        </div>
        <div>
          <label class="mb-2 text-sm">Timezone</label>
          <TimezoneSelector v-model="reminder.timezone" />
          <p v-if="errors.timezone" class="mt-2 text-red-500 text-xs italic">
            {{ errors.timezone.error }}
          </p>
        </div>

        <div class="flex flex-col xl:flex-row lg:flex-row justify-between">
          <div class="w-full">
            <label class="mb-2 text-sm">Schedule date</label>
            <EDatePicker
              v-model="reminder.date"
              :format="'MMM D, YYYY h:mm A'"
              :value-format="'YYYY-MM-DD h:mm A'"
              :read-only="isLoading"
              :disabled-below-dates="true"
              :placeholder="'Pick a Date and Time'"
              :clearable="true"
            />
            <p v-if="errors.date" class="mt-2 text-red-500 text-xs italic">
              {{ errors.date.error }}
            </p>
          </div>
        </div>
        <!-- companies selection -->

        <div v-if="isSuperAdmin" class="static z-30">
          <label class="mb-2 text-sm">Assign to companies</label>
          <ECombobox
            v-model="selectedCompanies"
            name="companies"
            :options="companies"
            option-name="name"
            value-key="id"
            :multiple="true"
            :filterable="true"
            :clearable="true"
            :disabled="companyLoading"
            @change="handleChangeCompany"
            placeholder="Start typing to search companies...."
          />
        </div>
        <!-- end of companies selections -->
        <!-- dealers selection -->

        <div
          v-if="
            (isSuperAdmin || isAccountManager) &&
            filteredDealers.length &&
            !isSingleUser
          "
          class="static z-20"
        >
          <label class="mb-2 text-sm">Assign to dealers</label>
          <ECombobox
            v-model="selectedDealers"
            name="dealers"
            :options="filteredDealers"
            option-name="name"
            value-key="id"
            :multiple="true"
            :filterable="true"
            :clearable="true"
            :disabled="companyLoading"
            @change="handleChangeDealer"
            placeholder="Start typing to search dealers...."
          />
        </div>
        <!-- end of dealers selection -->
        <div class="flex flex-col" v-if="!isSingleUser">
          <label class="mb-2 text-sm">Assign to users</label>
          <e-combobox
            class="w-full"
            v-model="selectedUsers"
            :options="users"
            placeholder="Start typing to search for users..."
            value-key="id"
            option-name="name"
            :multiple="true"
            :filterable="true"
            :clearable="true"
            collapse-tags
            collapse-tags-tooltip
          />
          <p v-if="errors.assignUsers" class="mt-2 text-red-500 text-xs italic">
            {{ errors.assignUsers.error }}
          </p>
        </div>
      </div>
    </template>
  </GlobalDialog>
</template>
