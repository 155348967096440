<script setup>

import { ElPopover } from "element-plus";
const routes = [
  {
    name: "Assignment History",
    to: "/reports?tab=assignment-history",
    urlType: "lms",
  },
  {
    name: "User Progress",
    to: "/reports?tab=course-overview",
    urlType: "lms",
  },
  {
    name: "Leaderboards",
    to: "/leaderboard",
    urlType: "lms",
  },
];


</script>
<template>
  <div v-bind="$attrs">
    <el-popover :width="220" popper-style="padding: 5px !important;z-index: 3000 !important">
      <template #reference>
        <a
          class="text-untitled-gray-500 border-transparent hover:border-untitled-gray-300 border-b-2 hover:text-untitled-gray-700 px-1 pt-1 text-sm w-full h-full flex justify-center items-center font-medium"
        >
          Reports
        </a>
      </template>
      <template #default>
        <div class="w-full flex flex-col justify-start items-start">
          <div
            class="w-full"
            v-for="(route, index) in routes "
            :key="index"
          >
            <router-link
              class="w-full px-2 py-2 hover:bg-gray-200 text-sm text-untitled-gray-700 flex justify-start items-start"
              :to="route.to"
            >
              {{ route.name }}
            </router-link>
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>
<style lang="scss" scoped></style>
