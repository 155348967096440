import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { http } from "@/composable/httpService";
import { useCategoryApi } from "@/endpoints/category.api";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useCourseApi } from "@/endpoints/courses.api";

export const useAllAvailableCourseStore = defineStore(
  "allAvailableCourseStore",
  () => {
    const categories = ref([]);
    const loadingCategories = ref(true);
    const coursesPerCategory = ref([]);
    const loadingCoursesPerCategory = ref(false);
    const courses = ref([]);
    const loadingCourses = ref(false);

    const handleFetchCategories = async (show_courses = true) => {
      try {
        loadingCategories.value = true;
        const response = await http().get(useCategoryApi.get(), {
          params: { is_available: true,show_courses: show_courses },
        });
        categories.value = response.data;
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingCategories.value = false;
      }
    };

    const handleFetchCourseByCategory = async (categoryId) => {
      const checkIfCourseExists = coursesPerCategory.value
        .map((course) => course.categoryId)
        .includes(categoryId);
      if (checkIfCourseExists) {
        return;
      }

      let courses = {
        categoryId,
        courses: [],
      };

      try {
        loadingCoursesPerCategory.value = true;
        const response = await http().get();
        courses.courses = response.data;
        coursesPerCategory.value.push(courses);
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingCoursesPerCategory.value = false;
      }
    };

    const handleFetchCoursesByCategoryId = async (categoryId) => {
      try {
        loadingCourses.value = true;
        const response = await http().get(
          useCourseApi.getCoursesById(categoryId)
        );
        courses.value = response.data;
        categories.value = categories.value.map((category) => {
          if (category.id === categoryId) {
            category.courses = courses.value;
          }
          return category;
        });
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingCourses.value = false;
      }
    };

    const handleFilterCourses = (categoryId) => {
      courses.value = categories.value.find(
        (category) => category.id === categoryId
      ).courses;
    };

    const handleFilterCategoriesCourses = (courseId) => {
      if (!courseId) {
        return;
      }
      categories.value = categories.value.map((category) => {
        if (category && category.courses && category.courses.length) {
          category.courses = category.courses.map((course) => {
            if (course.id === courseId) {
              course.is_enrolled = true;
            }
            return course;
          });
        }
        return category;
      });
    };

    return {
      categories,
      loadingCategories,
      coursesPerCategory,
      courses,
      loadingCourses,
      handleFetchCategories,
      handleFetchCourseByCategory,
      handleFetchCoursesByCategoryId,
      handleFilterCourses,
      handleFilterCategoriesCourses,
    };
  }
);
